<template>
  <div :class="['form__control mb-20', classForm, errors?.length || serverErrors?.length ? 'error' : '', isMinHeight ? 'min-height' : '']">
    <div :id="id">
      <VueMultiselect
        :class="[classes, IsLengthInput ? 'active' : '', multiple ? 'multiple' : '', isCustomBorder ? '' : 'border-default' ]"
        :track-by="trackBy"
        :id="id"
        :placeholder="''"
        :label="label"

        :multiple="multiple"
        v-model="selected"
        :options="options"
        :allow-empty="allowEmpty"
        :clear-on-select="clearOnSelect"
        :close-on-select="closeOnSelect"
        :searchable="isSearch"
        :key-record="keyRecord"
        :internal-search="!isCustomOption"
        :loading="isLoading && loader"
        :disabled="disabled"
        deselect-group-label="Не удалось исключить элемент"
        deselect-label="Не удалось исключить элемент"

        @search-change="asyncField"
        @remove="onRemove"
        @close="onTouch"
        @focusin="isFocus = true"
        @focusout="isFocus = false"
      />
      <label
        :class="['label--placeholder', IsLengthInput ? 'active' : '', disabled ? 'disabled' : '']"
        :for="id">
        {{ placeholder }}
      </label>
    </div>

    <div v-if="errors?.length || serverErrors?.length">
      <small class="error-small" v-if="errors[0]?.$message">{{ errors[0].$message }}</small>
      <small class="error-small" v-if="serverErrors?.length">{{ serverErrors[0] }}</small>
    </div>
    <small v-if="description && !errors?.length" class="description">{{ description }}</small>
  </div>

</template>

<script>
import VueMultiselect from 'vue-multiselect'
import { computed, reactive, ref, watch } from 'vue'
import { fieldProps } from '@/mixin/props'
import { DELAY_SEARCH } from '@/utils/consts'

export default {
  emits: ['search', 'remove'],
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      required: false,
      default: true
    },
    options: {
      type: Array,
      required: true,
      default: reactive([])
    },
    modelValue: {
      type: [String, Number, Object, Array]
    },
    classes: {
      type: [String, Array]
    },
    placeholder: {
      type: String,
      required: ''
    },
    serverErrors: {
      type: Array,
      required: false
    },
    isSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      required: false,
      default: true
    },
    sendEntirely: {
      type: Boolean,
      required: false
    },
    clearOnSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    isClear: {
      type: Boolean,
      required: false,
      default: false
    },
    loader: {
      type: Boolean,
      required: false,
      default: false
    },
    isCustomOption: {
      type: Boolean,
      required: false,
      default: true
    },
    label: {
      type: String,
      required: false,
      default: 'name'
    },
    keyRecord: {
      type: String,
      required: false,
      default: 'id'
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    isMinHeight: {
      type: Boolean,
      required: false,
      default: true
    },
    isCustomBorder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [fieldProps],
  setup (props, { emit }) {
    const selected = ref(null)
    const isLoading = ref(false)
    const isMaxHeightEffect = ref(false)
    const isFocus = ref(false)

    const IsLengthInput = computed(() => {
      if (props.multiple) {
        return selected.value?.length || isFocus.value
      }
      return selected.value || isFocus.value
    })

    function setSelectedValue () {
      if (!props.modelValue && props.modelValue !== 0) return

      if (props.multiple || props.sendEntirely) {
        selected.value = props.modelValue
      } else {
        selected.value = props.options.find(el => el[props.keyRecord] === +props.modelValue)
      }
    }

    watch(props.options, () => {
      setSelectedValue()
    })

    const getModelValue = computed(() => {
      setSelectedValue()
      return props.modelValue
    })

    watch(getModelValue, () => {
    })

    const changeIsMaxHeight = () => {
      const $select = document.getElementById(`${props.id}`)
      isMaxHeightEffect.value = $select.offsetHeight > 110
    }

    const asyncField = (val) => {
      if (!val) return
      if (isLoading.value) return
      isLoading.value = true
      setTimeout(() => {
        isLoading.value = false
      }, DELAY_SEARCH)
      emit('search', val)
    }

    const onTouch = () => {
      if (props.multiple) {
        changeIsMaxHeight()
      }
      emitHandler(props.name, selected.value)
      if (props.serverErrors?.length) props.serverErrors.length = 0
    }

    const emitHandler = (name, value) => {
      if (props.multiple || props.sendEntirely) {
        checkEmit(value)
      } else {
        checkEmit(value?.id)
      }
      if (value?.id) {
        emit('input', name, value.id)
      } else {
        emit('input', name, value)
      }
    }

    const checkEmit = (result) => {
      emit('update:modelValue', result)
    }
    const onRemove = (opt, id) => {
      emit('remove', opt, id)
    }

    return {
      onRemove,
      IsLengthInput,
      asyncField,
      isLoading,
      selected,
      onTouch,
      isMaxHeightEffect,
      isFocus
    }
  },
  components: {
    VueMultiselect
  }
}
</script>
